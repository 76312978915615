import styled from 'styled-components'

export default styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-legacy-8);

  :not(:first-child) {
    margin-top: var(--spacing-legacy-15);
  }
`
