import styled from 'styled-components'

const StyledCheckbox = styled.label`
  user-select: none;
  cursor: pointer;
  width: 100%;

  input[type='checkbox'] {
    display: none;

    :checked {
      + .checkbox-replacer {
        background-color: var(--color-primary);

        :before {
          border-color: var(--color-gray-19);
        }
      }
    }

    + .checkbox-replacer {
      cursor: pointer;
      margin: 0;
      background-color: var(--color-gray-32);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--border-radius-6);
      width: var(--spacing-legacy-20);
      height: var(--spacing-legacy-20);

      :before {
        content: '';
        display: block;
        width: 22%;
        height: 37%;
        border: 0;
        border-color: var(--color-gray-128);
        border-style: solid;
        border-bottom-width: 2px;
        border-right-width: 2px;
        transform: translateY(-22%) rotateZ(45deg);
      }
    }
  }

  .label {
    margin-left: var(--spacing-legacy-5);
    font-size: var(--spacing-legacy-13);
    color: var(--color-gray-128);
  }
`

export default StyledCheckbox
