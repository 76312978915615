import * as React from 'react'

import { useRecommendedSearchedNewsKeywordsQuery } from '@/gql/generated-news'
import { newsServerApolloClient } from '@/apollo-client'
import useLanguage from '@/utils/language/language-hook'

const newsPagePerLength = 20

export default function useRecommendedSearchNewsKeyword() {
  const { language } = useLanguage()
  const { data, loading } = useRecommendedSearchedNewsKeywordsQuery({
    variables: {
      language,
      limit: newsPagePerLength,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    client: newsServerApolloClient(),
  })

  const recommendedKeywords = React.useMemo(
    () => data?.recommendedSearchedNewsKeywords || [],
    [data?.recommendedSearchedNewsKeywords],
  )

  return {
    data,
    loading,
    recommendedKeywords,
  }
}
