import styled from 'styled-components'

const StyledNewsKeywordsNotificationInput = styled.div`
  .search-keyword-input {
    margin-top: var(--spacing-legacy-15);
    border-radius: var(--border-radius-12);
    background-color: var(--color-gray-27);

    input {
      font-size: var(--font-size-14);
    }
  }
`

export const StyledAddButton = styled.button`
  word-break: keep-all;
  color: var(--color-primary);

  :disabled {
    opacity: 0.5;
  }
`

export default StyledNewsKeywordsNotificationInput
