import * as React from 'react'

import { useMobileSize } from '@/utils/browser/resize-hook'
import { getSizeBy375 } from '@/utils/style/size'

import StyledCheckbox from './styled'

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: React.ReactNode
  sizeXY?: number
  fullSize?: boolean
  round?: boolean
}

const Checkbox = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { name, label, sizeXY = 20, fullSize = false, onChange, round, ...checkboxProps } = props
  const { isMobile } = useMobileSize()
  const checkboxReplacerStyle = React.useMemo(() => {
    const size = isMobile ? getSizeBy375(sizeXY) : `${sizeXY}px`
    return { width: size, height: size, borderRadius: round ? '50%' : undefined }
  }, [isMobile, sizeXY, round])
  const containerStyle = React.useMemo(() => {
    return fullSize ? { width: '100%', height: '100%' } : undefined
  }, [fullSize])

  return (
    <StyledCheckbox className="--flex-x-center" style={containerStyle}>
      <input ref={ref} name={name} type="checkbox" onChange={onChange} {...checkboxProps} />
      <span className="--flex-shrink-off checkbox-replacer" style={checkboxReplacerStyle} />
      <span className="label">{label}</span>
    </StyledCheckbox>
  )
})

Checkbox.displayName = 'Checkbox'

export default Checkbox
