import {
  MyCrawlingNewsKeywordsDocument,
  MyCrawlingNewsKeywordsQuery,
  MyCrawlingNewsKeywordsQueryVariables,
  useAddCrawlingNewsKeywordMutation,
} from '@/gql/generated-news'
import { newsServerApolloClient } from '@/apollo-client'

export default function useAddNewsKeyword() {
  return useAddCrawlingNewsKeywordMutation({
    client: newsServerApolloClient(),
    update(cache, { data }) {
      cache.updateQuery<MyCrawlingNewsKeywordsQuery, MyCrawlingNewsKeywordsQueryVariables>(
        { query: MyCrawlingNewsKeywordsDocument },
        (cachedData) => {
          if (cachedData?.myCrawlingNewsKeywords && data?.addCrawlingNewsKeyword) {
            return {
              ...cachedData,
              myCrawlingNewsKeywords: [
                ...cachedData.myCrawlingNewsKeywords,
                {
                  ...data.addCrawlingNewsKeyword,
                  __typename: 'UserCrawlingNewsKeyword',
                  isUserReceivingKeywordNotification: true,
                },
              ],
            }
          }
        },
      )
    },
    optimisticResponse: ({ keyword }) => ({
      addCrawlingNewsKeyword: {
        id: `${new Date().getTime()}`,
        keyword,
      },
    }),
  })
}
