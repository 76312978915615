export type CurrencyUnit = '₩' | '$' | '¥' | '€'
export enum CurrencyUnitEnum {
  KRW = '₩',
  USD = '$',
  JPY = '¥',
  CNY = '¥',
  EUR = '€',
}
export enum LocaleCurrencyUnitEnum {
  ko = '₩',
  en = '$',
}
export enum CountryCurrencyUnitEnum {
  KR = '₩',
  US = '$',
}

export type CurrencySymbol = 'KRW' | 'USD' | 'JPY' | 'CNY' | 'EUR'
export enum CurrencySymbolEnum {
  KRW = 'KRW',
  USD = 'USD',
  JPY = 'JPY',
  CNY = 'CNY',
  EUR = 'EUR',
}
export enum LocaleCurrencySymbolEnum {
  ko = 'KRW',
  en = 'USD',
}
export enum CountryCurrencySymbolEnum {
  KR = 'KRW',
  US = 'USD',
}

export enum CryptoBaseSymbolEnum {
  BTC = 'BTC',
  ETH = 'ETH',
  LTC = 'LTC',
  BCH = 'BCH',
  XRP = 'XRP',
  ADA = 'ADA',
  DOT = 'DOT',
  EOS = 'EOS',
  DOGE = 'DOGE',
  NEO = 'NEO',
  ETC = 'ETC',
}
