import * as React from 'react'

import useLanguage from '@/utils/language/language-hook'

import { putZero } from '../calendar-lib/shared/generalUtils'
import withClientOnly from '../client-only/hoc'

type Props = {
  className?: string
  dateDependency?: unknown
}

const TodayTime = ({ className, dateDependency }: Props) => {
  const { t } = useLanguage('common')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const date = React.useMemo(() => new Date(), [dateDependency])
  return (
    <p suppressHydrationWarning className={className}>
      {t('today', { hours: date.getHours(), minutes: putZero(date.getMinutes()) })}
    </p>
  )
}

export default withClientOnly(TodayTime)
