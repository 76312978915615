import { defaultToast, successToast, infoToast, errorToast, warnToast } from '.'

const toasts = {
  defaultToast,
  successToast,
  infoToast,
  errorToast,
  warnToast,
}

const useToast = () => {
  return toasts
}

export default useToast
