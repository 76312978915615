import * as React from 'react'
import NextImage, { ImageProps } from 'next/image'

type Props = {
  wrapperRef?: React.RefObject<HTMLElement>
} & ImageProps

// src props이 nullish 값에 에러로 반응해서 대응할 수 있도록 처리
const Image = (props: Props) => {
  const { wrapperRef, ...imageProps } = props

  return props.src ? (
    <NextImage
      onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
        e.currentTarget.style.display = 'block'
      }}
      onError={(e) => {
        // 상위 엘리먼트가 전달된 경우 상위 엘리먼트를 hidden 시킴
        if (wrapperRef?.current?.style) {
          wrapperRef.current.style.display = 'none'
        } else {
          e.currentTarget.style.display = 'none'
        }
      }}
      // 기존 설정값. 아이콘이미지에서 많이 사용하기 때문에 50 x 50 으로 주고 사용하는 컴포넌트측에서 랩퍼 엘리먼트로 가로세로 지정하여 사용
      layout="responsive"
      width={50}
      height={50}
      {...imageProps}
    />
  ) : null
}

export default Image
