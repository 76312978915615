import * as React from 'react'

import useLanguage from '@/utils/language/language-hook'
import useChangeNewsKeywordStatus from '@/service/news/change-news-keyword-hook'

import StyledNewsKeywordsNotification from './styled'
import RecommendedNewsKeywordsNotification from './news-keywords-notification-recommend'
import NewsKeywordsNotificationInput from './news-keywords-notification-input'
import NewsKeywordsNotificationList from './news-keywords-notification-list'

const NewsKeywordsNotification = () => {
  const { t } = useLanguage('account')
  const { keywords, keywordsMap, changeNewsKeywordStatus } = useChangeNewsKeywordStatus({
    hasToast: false,
  })

  return (
    <StyledNewsKeywordsNotification>
      <p>{t('notificationSetting.newsKeywords.desc')} 🔔</p>
      <NewsKeywordsNotificationInput changeNewsKeywordStatus={changeNewsKeywordStatus} />
      <hr />
      {!!keywords.length && (
        <>
          <NewsKeywordsNotificationList
            keywords={keywords}
            changeNewsKeywordStatus={changeNewsKeywordStatus}
          />
          <hr className="keywords-separator" />
        </>
      )}
      <RecommendedNewsKeywordsNotification
        keywordsMap={keywordsMap}
        changeNewsKeywordStatus={changeNewsKeywordStatus}
      />
    </StyledNewsKeywordsNotification>
  )
}

export default NewsKeywordsNotification
