import * as React from 'react'

import type { ChangeNewsKeywordStatus } from '@/service/news/change-news-keyword-hook'
import useLanguage from '@/utils/language/language-hook'
import Input from '@/components/form/input'
import sendGaCustomEvent from '@/service/event-log/gtag-custom-event'

import StyledNewsKeywordsNotificationInput, { StyledAddButton } from './styled'

type Props = {
  changeNewsKeywordStatus: ChangeNewsKeywordStatus
}

const NewsKeywordsNotificationInput = ({ changeNewsKeywordStatus }: Props) => {
  const { t } = useLanguage('account')
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [disabled, setDisabled] = React.useState(true)
  const handleInputChange = React.useCallback((value: string) => {
    if (inputRef.current) {
      inputRef.current.value = value
    }
    setDisabled(!value)
  }, [])
  const addNewsKeywordStatus = React.useCallback(() => {
    if (!inputRef.current) return
    const keyword = inputRef.current.value.trim()
    sendGaCustomEvent({
      gaCategory: 'NEWS',
      gaAction: 'NEWS_ADD-KEYWORD-NOTIFICATION_CLICK',
      gaLabel: `NEWS_ADD-KEYWORD-NOTIFICATION_${keyword}`,
    })
    if (keyword) {
      changeNewsKeywordStatus({
        keyword,
        action: 'add',
        onAddCompeleted: () => handleInputChange(''),
      })
    }
  }, [changeNewsKeywordStatus, handleInputChange])
  return (
    <StyledNewsKeywordsNotificationInput>
      <Input
        ref={inputRef}
        wrapperClassName="search-keyword-input"
        name="newsKeyword"
        placeholder={t('notificationSetting.newsKeywords.placeholder')}
        emptyPlaceholder=""
        onChange={(e) => {
          const value = e.currentTarget.value
          handleInputChange(value)
        }}
        onEnterKey={addNewsKeywordStatus}
        suffix={
          <StyledAddButton type="button" onClick={addNewsKeywordStatus} disabled={disabled}>
            {t('common:add')}
          </StyledAddButton>
        }
      />
    </StyledNewsKeywordsNotificationInput>
  )
}

export default NewsKeywordsNotificationInput
