import styled from 'styled-components'

const StyledNewsKeywordsNotification = styled.div`
  padding-bottom: var(--spacing-legacy-15);
  margin-top: var(--spacing-legacy-20);

  > *:not(.keywords-separator) {
    margin-left: var(--spacing-legacy-15);
    margin-right: var(--spacing-legacy-15);
  }

  > p {
    line-height: 1.4;
    color: var(--color-gray-197);
    font-size: var(--font-size-13);
  }

  > hr {
    border: none;
    border-top: 1px solid var(--color-gray-27);
    margin: var(--spacing-legacy-25) 0;
  }
`

export const StyledKeywordsList = styled.ul`
  font-size: var(--font-size-15);

  .keyword {
    color: var(--color-gray-197);
  }

  li {
    :not(:first-child) {
      margin-top: var(--spacing-legacy-24);
    }
  }

  + .keywords-separator {
    border: none;
    border-top: var(--spacing-legacy-8) solid var(--color-gray-27);
    margin: var(--spacing-legacy-25) 0;
  }

  button {
    font-size: inherit;
    color: var(--color-gray-128);

    &.primary {
      color: var(--color-primary);
    }
  }
`

export default StyledNewsKeywordsNotification
