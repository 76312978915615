import * as React from 'react'

import useLanguage from '@/utils/language/language-hook'
import { defaultToast } from '@/utils/toast'
import ToastWithButton from '@/utils/toast/toast-with-button'

export type NewsKeywordNotificationToastProps =
  | {
      type: 'remove' | 'add' | 'maximum' | 'alreadyExist'
      onClick?: () => void
    }
  | {
      type: 'addWithMore'
      onClick: () => void
    }

export default function useNewsKeywordNotificationToast() {
  const { t } = useLanguage('account')
  const toast = React.useCallback(
    ({ type, onClick }: NewsKeywordNotificationToastProps) => {
      switch (type) {
        case 'addWithMore': {
          defaultToast(
            <ToastWithButton
              desc={t('notificationSetting.newsKeywords.add')}
              buttonText={t('notificationSetting.newsKeywords.showNewsKeywords')}
              onButtonClick={onClick}
            />,
          )
          return
        }
        default: {
          defaultToast(t(`notificationSetting.newsKeywords.${type}`))
          return
        }
      }
    },
    [t],
  )

  return { toast }
}
