import * as React from 'react'

import useNewsKeywordNotificationToast, {
  NewsKeywordNotificationToastProps,
} from '@/service/news-keyword-notification/toast-hook'
import { useMyCrawlingNewsKeywordsQuery } from '@/gql/generated-news'
import { newsServerApolloClient } from '@/apollo-client'
import useAddNewsKeyword from '@/gql/news/add-news-keyword-hook'
import useRemoveNewsKeyword from '@/gql/news/remove-news-keyword-hook'

type AddCompletedToastType = Extract<
  NewsKeywordNotificationToastProps['type'],
  'add' | 'addWithMore'
>

export type ChangeNewsKeywordStatusAction = 'add' | 'remove'

export type ChangeNewsKeywordStatus = ReturnType<
  typeof useChangeNewsKeywordStatus
>['changeNewsKeywordStatus']

type Props = {
  keyword?: string
  hasToast?: boolean
}

const NEWS_KEYWORDS_LIMIT = 10

/**
 * 서버에 뉴스 키워드 등록 상태를 변경
 * 뮤테이션 후 토스트 팝업 내장
 */
export default function useChangeNewsKeywordStatus({ keyword, hasToast = true }: Props = {}) {
  const client = React.useMemo(newsServerApolloClient, [])
  const { toast } = useNewsKeywordNotificationToast()
  const { data, loading } = useMyCrawlingNewsKeywordsQuery({
    client,
    fetchPolicy: 'cache-and-network',
  })
  const keywordsMap = React.useMemo(() => {
    const keywords = data?.myCrawlingNewsKeywords || []
    return new Map(keywords.map(({ keyword }) => [keyword, true]))
  }, [data?.myCrawlingNewsKeywords])
  const hasKeyword = React.useMemo(() => {
    return !!keyword && keywordsMap.has(keyword)
  }, [keyword, keywordsMap])
  const keywords = React.useMemo(
    () => data?.myCrawlingNewsKeywords || [],
    [data?.myCrawlingNewsKeywords],
  )
  const [addNewsKeyword] = useAddNewsKeyword()
  const [removeNewsKeyword] = useRemoveNewsKeyword({ keywords })
  const changeNewsKeywordStatus = React.useCallback(
    ({
      keyword,
      action,
      onToastMoreShowClick = 'add',
      onAddCompeleted,
    }: {
      keyword: string
      action: ChangeNewsKeywordStatusAction
      onToastMoreShowClick?: AddCompletedToastType | (() => void)
      onAddCompeleted?: () => void
    }) => {
      if (!data?.myCrawlingNewsKeywords) return
      const keywordsCount = keywordsMap.size
      if (action === 'add') {
        if (keywordsMap.has(keyword)) {
          toast({ type: 'alreadyExist' })
          return
        }
        if (keywordsCount >= NEWS_KEYWORDS_LIMIT) {
          toast({ type: 'maximum' })
        } else {
          onAddCompeleted && onAddCompeleted() // input 값 제거같은 선조치 로직 실행위해서 onCompleted 앞에 둠
          addNewsKeyword({
            variables: { keyword },
            onCompleted: () => {
              hasToast &&
                toast(
                  typeof onToastMoreShowClick === 'function'
                    ? { type: 'addWithMore', onClick: onToastMoreShowClick }
                    : { type: 'add' },
                )
            },
          })
        }
      } else {
        removeNewsKeyword({
          variables: { keyword },
          onCompleted: () => {
            hasToast && toast({ type: 'remove' })
          },
        })
      }
    },
    [addNewsKeyword, data?.myCrawlingNewsKeywords, hasToast, keywordsMap, removeNewsKeyword, toast],
  )

  return {
    keywords: data?.myCrawlingNewsKeywords || [],
    keywordsMap,
    hasKeyword,
    changeNewsKeywordStatus,
    loading,
  }
}
