import styled from 'styled-components'

const StyledToastWithButton = styled.div`
  > p {
    margin-right: var(--spacing-legacy-10);
    font-size: var(--font-size-14);
  }

  button {
    font-size: var(--font-size-14);
    color: var(--color-primary);
  }
`

export default StyledToastWithButton
