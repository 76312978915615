import styled from 'styled-components'

const StyledRecommendedNewsKeywordsNotification = styled.div`
  > .header {
    margin-bottom: var(--spacing-legacy-16);

    h2 {
      font-size: var(--font-size-16);
      color: var(--color-white);
    }

    .today {
      color: var(--color-gray-128);
    }
  }
`

export default StyledRecommendedNewsKeywordsNotification
