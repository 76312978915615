import * as React from 'react'

import type { ChangeNewsKeywordStatus } from '@/service/news/change-news-keyword-hook'
import useLanguage from '@/utils/language/language-hook'
import sendGaCustomEvent from '@/service/event-log/gtag-custom-event'
import Skeleton from '@/components/common/skeleton'
import useRecommendedSearchNewsKeyword from '@/gql/news/recommended-search-news-keyword-hook'
import TodayNow from '@/components/common/today-now'

import { StyledKeywordsList } from '../styled'

import StyledRecommendedNewsKeywordsNotification from './styled'

type Props = {
  keywordsMap: Map<string, boolean>
  changeNewsKeywordStatus: ChangeNewsKeywordStatus
}

const RecommendedNewsKeywordsNotification = ({ keywordsMap, changeNewsKeywordStatus }: Props) => {
  const { t } = useLanguage('common')
  const { recommendedKeywords, loading } = useRecommendedSearchNewsKeyword()

  return (
    <StyledRecommendedNewsKeywordsNotification>
      <div className="--flex-x-y-between header">
        <h2>{t('news:recommendSearchKeyword')}</h2>
        <TodayNow className="today" />
      </div>
      <StyledKeywordsList>
        {!recommendedKeywords.length && loading && (
          <li>
            <div className="--flex-x-y-between">
              <Skeleton loading pcWidth={100} pcHeight={16} />
              <Skeleton loading pcWidth={50} pcHeight={16} />
            </div>
          </li>
        )}
        {recommendedKeywords.map(({ id, keyword }) => {
          const hasKeyword = keywordsMap.has(keyword)
          return (
            <li key={id}>
              <div className="--flex-x-y-between">
                <div className="keyword">{keyword}</div>
                <button
                  type="button"
                  className={hasKeyword ? undefined : 'primary'}
                  onClick={() => {
                    const action = hasKeyword ? 'REMOVE' : 'ADD'
                    sendGaCustomEvent({
                      gaCategory: 'NEWS',
                      gaAction: `NEWS_${action}-KEYWORD-NOTIFICATION_CLICK`,
                      gaLabel: `NEWS_${action}-KEYWORD-NOTIFICATION_${keyword}`,
                    })
                    changeNewsKeywordStatus({
                      keyword,
                      action: hasKeyword ? 'remove' : 'add',
                    })
                  }}>
                  {t(hasKeyword ? 'added' : 'add')}
                </button>
              </div>
            </li>
          )
        })}
      </StyledKeywordsList>
    </StyledRecommendedNewsKeywordsNotification>
  )
}

export default RecommendedNewsKeywordsNotification
