import {
  CrawlingNewsKeyword,
  MyCrawlingNewsKeywordsDocument,
  MyCrawlingNewsKeywordsQuery,
  MyCrawlingNewsKeywordsQueryVariables,
  useRemoveCrawlingNewsKeywordMutation,
} from '@/gql/generated-news'
import { newsServerApolloClient } from '@/apollo-client'

export default function useRemoveNewsKeyword({
  keywords,
}: {
  keywords: Pick<CrawlingNewsKeyword, 'id' | 'keyword'>[]
}) {
  return useRemoveCrawlingNewsKeywordMutation({
    client: newsServerApolloClient(),
    update(cache, { data }) {
      cache.updateQuery<MyCrawlingNewsKeywordsQuery, MyCrawlingNewsKeywordsQueryVariables>(
        { query: MyCrawlingNewsKeywordsDocument },
        (cachedData) => {
          if (cachedData?.myCrawlingNewsKeywords && data?.removeCrawlingNewsKeyword) {
            return {
              ...cachedData,
              myCrawlingNewsKeywords: [
                ...cachedData.myCrawlingNewsKeywords.filter(
                  (k) => k.keyword !== data.removeCrawlingNewsKeyword?.keyword,
                ),
              ],
            }
          }
        },
      )
    },
    optimisticResponse: ({ keyword }) => ({
      removeCrawlingNewsKeyword: keywords.find((k) => k.keyword === keyword),
    }),
  })
}
