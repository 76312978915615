import * as React from 'react'

import StyledToastWithButton from './styled'

type Props = {
  desc: string
  buttonText: string
  onButtonClick: () => void
}

const ToastWithButton = ({ desc, buttonText, onButtonClick }: Props) => {
  return (
    <StyledToastWithButton className="--flex-x-y-between">
      <p>{desc}</p>
      <button type="button" className="--flex-shrink-off" onClick={onButtonClick}>
        {buttonText}
      </button>
    </StyledToastWithButton>
  )
}

export default ToastWithButton
