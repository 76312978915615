import {
  CurrencySymbol,
  CurrencyUnit,
  LocaleCurrencySymbolEnum,
  LocaleCurrencyUnitEnum,
  CountryCurrencyUnitEnum,
  CountryCurrencySymbolEnum,
} from '@/types/currency'
import { CountryEnum } from '@/types/country'
import useLanguage from '@/utils/language/language-hook'

type Props = {
  country?: CountryEnum | null
}

export type Return = {
  symbol: CurrencySymbol
  unit: CurrencyUnit
}

// 로케일 및 국가에 따른 통화 정보 반환
const useLocaleCurrency = ({ country }: Props | undefined = {}): Return => {
  const { language } = useLanguage()

  if (country) {
    const unit = CountryCurrencyUnitEnum[country] ?? CountryCurrencyUnitEnum.US
    const symbol = CountryCurrencySymbolEnum[country] ?? CountryCurrencySymbolEnum.US

    return { unit, symbol }
  }

  const unit = LocaleCurrencyUnitEnum[language] ?? LocaleCurrencyUnitEnum.en
  const symbol = LocaleCurrencySymbolEnum[language] ?? LocaleCurrencySymbolEnum.en

  return { unit, symbol }
}

export default useLocaleCurrency
