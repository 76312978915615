import * as React from 'react'

import type { ChangeNewsKeywordStatus } from '@/service/news/change-news-keyword-hook'
import CloseButton from '@/components/buttons/close-button'
import sendGaCustomEvent from '@/service/event-log/gtag-custom-event'
import { UserCrawlingNewsKeywordFieldsFragment } from '@/gql/generated-news'

import { StyledKeywordsList } from '../styled'

type Props = {
  keywords: UserCrawlingNewsKeywordFieldsFragment[]
  changeNewsKeywordStatus: ChangeNewsKeywordStatus
}

const NewsKeywordsNotificationList = ({ keywords, changeNewsKeywordStatus }: Props) => {
  return (
    <StyledKeywordsList>
      {keywords.map(({ id, keyword }) => (
        <li key={id}>
          <div className="--flex-x-y-between" data-test-id="news-keyword">
            <div className="keyword" data-test-id={`news-keyword-${keyword}`}>
              {keyword}
            </div>
            <CloseButton
              data-test-id={`remove-news-keyword-${keyword}`}
              size={20}
              onClick={() => {
                sendGaCustomEvent({
                  gaCategory: 'NEWS',
                  gaAction: 'NEWS_REMOVE-KEYWORD-NOTIFICATION_CLICK',
                  gaLabel: `NEWS_REMOVE-KEYWORD-NOTIFICATION_${keyword}`,
                })
                changeNewsKeywordStatus({ keyword, action: 'remove' })
              }}
            />
          </div>
        </li>
      ))}
    </StyledKeywordsList>
  )
}

export default NewsKeywordsNotificationList
